<template>
  <div>
    <div id="line_image" style="height: 200px"></div>
  </div>
</template>
<script>
import * as echarts from "echarts";

export default {
  name: "LineImage",
  data() {
    return {
      option: {
        title: {
          text: "工地形象",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "0%",
          left: "right",
          data: ["吸烟", "未穿工服", "工地不整洁"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["1月", "2月", "3月"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "吸烟",
            type: "line",
            stack: "Total",
            data: [120, 132, 101],
          },
          {
            name: "未穿工服",
            type: "line",
            stack: "Total",
            data: [220, 182, 191],
          },
          {
            name: "工地不整洁",
            type: "line",
            stack: "Total",
            data: [150, 232, 201],
          },
        ],
      },
    };
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("line_image"));
      // 绘制图表
      myChart.setOption(this.option);
    },
  },
};
</script>
